import { useSelector } from "react-redux";
import NotAuthHome from "./Components/NotAuthHome";
import MainAdminHome from "./Components/MainAdminHome";
import EditAdminHome from "./Components/EditAdminHome";


const Home = () => {
  const { Admin ,isLoggedIn} = useSelector((state) => state.user);
  console.log(Admin,isLoggedIn);

if(!isLoggedIn){
  return <NotAuthHome />
}

if(isLoggedIn && Admin.username==="Admin" ){
  return <MainAdminHome />
}


if(isLoggedIn && Admin.username==="Admin2" ){
  return <EditAdminHome/>
}


return(
  <div><p>error..</p></div>
)
 
};

export default Home;
