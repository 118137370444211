import { useState } from "react";
import { baseAPI } from "../Services/baseApi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import {handleSignIn} from "../Services/useSlice";

const Login = () => {

  const [login, setLogin] = useState({
    username: "",
    password: "",
  });
  const [showPass, setShowPass] = useState(false);
  const [err, setErr] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate=useNavigate()
const dispach=useDispatch()


  const handleLogIn = (e) => {
    e.preventDefault();
    setIsLoading(true); 

    baseAPI
      .post("login", login)
      .then((res) => {
        if (res) {
          console.log(res);
          localStorage.setItem("token", res.data.refreshToken);
          const decodedToken = jwtDecode(res.data.refreshToken);
          dispach(handleSignIn(decodedToken));
          setErr(null);
          navigate("/")
        }else {
          setErr("Incorrect credentials");
        }
      })
      .catch((err) => {
        setErr("Incorrect credentials");
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false); 
      });
  };

  return (
    <main className="w-full bg-neutral-100 p-3 flex flex-col gap-3 items-center min-h-screen">
      <h2 className="text-2xl mt-10 pl-2 font-bold text-textColor w-2/3 text-start">გაიარეთ ავტორიზაცია გასაგრძელებლად</h2>
      <form className="w-2/3 flex mt-3 flex-col gap-5 border bg-white p-5 h-fit">
        <div
          className={`flex relative flex-col gap-2 mt-3 w-full xl:w-2/5" 
                        `}
        >
          <label
            htmlFor="username"
            className="font-bold   text-base text-gray-600 lb ml-5 p-1"
          >
            Username
          </label>

          <input
            type="text"
            name="username"
            id="username"
            value={login.username}
            onChange={(e) => setLogin({ ...login, username: e.target.value })}
            className={`p-3 border rounded-md  border-gray-300 focus:outline-textColor focus-within:bg-white focus:bg-white `}
          />
        </div>
        <div
          className={`flex relative flex-col gap-2 mt-3 w-full xl:w-2/5" 
                        `}
        >
          <label
            htmlFor="password"
            className="font-bold   text-base text-gray-600 lb ml-5 p-1"
          >
            Password
          </label>

          <input
            type={showPass ? "text" : "password"}
            name="password"
            id="password"
            value={login.password}
            onChange={(e) => setLogin({ ...login, password: e.target.value })}
            className={`p-3 border rounded-md  border-gray-300 focus:outline-emerald-600 focus-within:bg-white focus:bg-white `}
          />
        </div>

        {err && (
          <div>
            <p className="text-red-500 text-base font-bold  mt-1 ml-1 h-fit">{err}</p>
          </div>
        )}

<button
          className="w-full p-3 bg-textColor text-white rounded hover:bg-sky-600 cursor-pointer transition-colors duration-300 ease text-lg font-bold relative"
          onClick={handleLogIn}
        >
          {isLoading ? (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <svg className="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.96 7.96 0 014 12H0c0 4.418 3.582 8 8 8v-4c-2.215 0-4.232-.897-5.657-2.343l1.414-1.414zm16.97-5.657A7.96 7.96 0 0120 12h4c0-6.627-5.373-12-12-12v4c2.215 0 4.232.897 5.657 2.343l-1.414 1.414z"></path>
              </svg>
            </div>
          ) : (
            "Login"
          )}
        </button>
      </form>
    </main>
  );
};

export default Login;