import EditIcon from "@mui/icons-material/Edit";

const Table = ({ applications, handleClick }) => {
  return (
    <div className="overflow-x-auto w-full ">
      <table className="min-w-full divide-y divide-gray-200 w-full">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 pt-5  text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r "
            >
              ნახვა
            </th>
            <th
              scope="col"
              className="px-6 py-3 pt-5  text-left  text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r "
            >
             აპლიკაციის ნომერი
            </th>
            <th
              scope="col"
              className="px-6 py-3 pt-5  text-left  text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r "
            >
              პროექტის სახელი
            </th>
            <th
              scope="col"
              className="px-6 py-3 pt-5  text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
            >
              აპლიკანტი
            </th>
            <th
              scope="col"
              className="px-6 py-3 pt-5  text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
            >
              შემოსვლის თარიღი
            </th>
            <th
              scope="col"
              className="px-6 py-3 pt-5  text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
            >
              სქესი
            </th>
            <th
              scope="col"
              className="px-6 py-3 pt-5  h-auto text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
            >
              აპლიკანტის ელ-ფოსტა
            </th>
            <th
              scope="col"
              className="px-6 py-3 pt-5  h-auto text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
            >
              აპლიკანტის ნომერი
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 ">
          {applications.map((item) => (
            <tr key={item.id}>
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                <button
                  type="button"
                  onClick={() => handleClick(item.id)}
                  className="text-textColor hover:text-sky-800"
                >
                  <EditIcon /> ნახვა
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                {item.applicationNumber}
              </td>
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                {item.projectName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                {item.applicantName} {item.applicantLastName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                {item.createdAt}
              </td>
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                {item.applicantGender}
              </td>
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                {item.applicantEmail}
              </td>
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                {item.applicantPhone}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
