const CommonModal = ({
  success,
  handleSubmit,
  setModal,
  successText,
  question,
}) => {
  return (
    <div className="fixed top-1/2 left-1/2 w-1/3 bg-white  z-30 transform -translate-x-1/2 -translate-y-1/2  shadow-lg shadow-gray-600 p-3 flex flex-col justify-center items-center">
      {success ? (
        <div className="p-10">
          <h2 className="text-xl text-green-600 font-semibold">
            {successText}
          </h2>
        </div>
      ) : (
        <div>
          <h2 className="font-medium mt-2 text-red-600 text-lg border-b pb-2">
            {question}
          </h2>
          <div className="flex justify-around w-full mt-3 p-3 ">
            <button
              onClick={handleSubmit}
              className=" text-red-600 hover:border-b hover:pb-1 font-bold text-lg p-2"
            >
              დიახ
            </button>
            <button
              onClick={() => setModal(false)}
              className=" text-textColor hover:border-b hover:pb-1 font-bold text-lg p-2"
            >
              არა
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommonModal;
