

const NewApplications = () => {


    return (
        <div>
      
            <p>ew pplications </p>
        </div>
    );
};

export default NewApplications;
