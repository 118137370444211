import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const Table = ({ handleModal, winners, handleClick }) => {
  return (
    <div className="overflow-x-auto  w-full ">
      <table className="min-w-full divide-y divide-gray-200 w-full">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 pt-5  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r "
              style={{
                minWidth: "100px",
              }}
            >
              წაშლა
            </th>
            <th
              scope="col"
              className="px-6 py-3 pt-5  text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r "
              style={{ minWidth: "100px" }}
            >
              დაედითება
            </th>
            <th
              scope="col"
              className="px-6 py-3 pt-5  text-left  text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r "
              style={{ minWidth: "200px" }}
            >
              სურათი
            </th>
            <th
              scope="col"
              className="px-6 py-3 pt-5  text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
            >
              თარიღი
            </th>
            <th
              scope="col"
              className="px-6 py-3 pt-5  h-auto text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
            >
              სათაური
            </th>
            <th
              scope="col"
              className="px-6 py-3 pt-5  h-auto text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
            >
              ქალაქი
            </th>
            <th
              scope="col"
              className="px-6 py-3 pt-5  h-auto text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
            >
              სტარტაპის ბიუჯეტი
            </th>
            <th
              scope="col"
              className="px-6 py-3 pt-5  h-auto text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
            >
              სრული ბიუჯეტი
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 ">
          {winners.map((item) => (
            <tr key={item.id}>
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                <button
                  type="button"
                  onClick={() => handleModal(item.id)}
                  className="text-red-600 hover:text-red-900"
                >
                  <DeleteIcon />
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                <button
                  type="button"
                  onClick={() => handleClick(item.id)}
                  className="text-textColor hover:text-sky-800"
                >
                  <EditIcon />
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                <img
                  className="w-52 h-32 object-cover"
                  src={item.img}
                  alt={`${item.title}_img`}
                />
              </td>
              
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                {item.date}
              </td>
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                {item.winnerName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                {item.city}
              </td>
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                {item.budjetFromStartupGeorgia}
              </td>
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                {item.totalBudjet}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;