import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Table from "./Components/Table";
import CommonModal from "../../Components/CommonModal";
import { baseAPI } from "../../Services/baseApi";

const AllNews = () => {
  const navigate = useNavigate();
  const [news, setNews] = useState({
    isLoading: true,
    data: [],
    error: false,
  });
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    baseAPI
      .get("/news/allnews")
      .then((res) => {
        // Sort the data by date from latest to oldest
        const sortedData = res.data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setNews({
          isLoading: false,
          data: sortedData,
          error: false,
        });
      })
      .catch((err) => {
        setNews({
          isLoading: false,
          data: [],
          error: true,
        });
      });
  }, []);

  if (news.isLoading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <h1>Loading...</h1>
      </div>
    );
  }

  if (news.error) {
    return (
      <div className="h-screen flex justify-center items-center">
        <h1>Error Loading News</h1>
      </div>
    );
  }

  const handleClick = (id) => {
    navigate(`/news/${id}`);
  };

  const handleDelete = (id) => {
    baseAPI
      .delete(`/news/delete/${id}`)
      .then((res) => {
        setSuccess(true);
        setNews({
          ...news,
          data: news.data.filter((item) => item.id !== id),
        });
        setTimeout(() => {
          setModal(false);
        }, 2000);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handlemodal = (id) => {
    setModal(true);
    setId(id);
    setSuccess(false);
  };

  return (
    <div className="w-full min-h-screen bg-neutral-100 flex  ">
      <Table
        news={news.data}
        handleClick={handleClick}
        handleModal={handlemodal}
      />
      {modal && (
        <CommonModal
          success={success}
          handleSubmit={() => handleDelete(id)}
          setModal={setModal}
          successText=" სიახლე წარმატებით წაიშალა!!"
          question="დარწმუნებული ხართ რომ გინდათ სიახლის წაშლა?"
        />
      )}
    </div>
  );
};

export default AllNews;