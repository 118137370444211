import { createSlice } from "@reduxjs/toolkit";


const initialState = {
Admin:{
  username:null,
  iat:null,
  exp:null,
},
isLoggedIn: false,
}
  
  
  const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
      handleSignIn: (state,action) => {
        state.isLoggedIn = true;
        state.Admin = action.payload;
      },

      handleSignOut: (state) => {
        state.isLoggedIn = false;
        localStorage.removeItem("token");
        state.Admin = {
          username: null,
          iat: null,
          exp: null,
        }
      },
   

      }
      },
  );


 export const {
 handleSignIn,
 handleSignOut,
  } = userSlice.actions;

  export default userSlice.reducer;