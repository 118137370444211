import React, { useState } from "react";
import { CommonInput } from "./CommonInput";

const Filters = ({ data, setData, original }) => {



 const labels=['სახელი',
 'გვარი',
' მეილი',
' მობილურის ნომერი',
 'პირადი ნომერი',
' მინ. დაბადების თარიღი', 
 'მაქს. დაბადების თარიღი',
' პროექტის სახელი',
' ინდუსტრია',
' მუნიციპალიტეტი',
' რეგიონი',
 'სოფელი',
 'ი.პ. საიდენტიფიკაციო კოდი',

]
  const [filters, setFilters] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    idNumber: "",
    birthdayMin: "",
    birthdayMax: "",
    projectName: "",
    industry: "",
    municipality: "",
    region: "",
    village: "",
    entityID: "",
  });

const [applicationId, setApplicationId] = useState(0);


console.log(data, applicationId);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSearchWithApplicationId = (e) => {
    e.preventDefault();
    const found = data.filter((item) => {
      return item.applicationNumber === parseInt(applicationId);
    });
  
    setData({
      ...data, 
      data: found, 
    });
  };
  

const applyFilters = () => {
  const filtered = data.filter((item) => {
    return (
        item.applicantName.toLowerCase().includes(filters.name.toLowerCase()) &&
        item.applicantLastName.toLowerCase().includes(filters.lastName.toLowerCase()) &&
        item.applicantEmail.toLowerCase().includes(filters.email.toLowerCase())  &&
         item.applicantPhone.toLowerCase().includes(filters.phone.toLowerCase()) &&
        item.applicantIdNumber.toLowerCase().includes(filters.idNumber.toLowerCase())  &&
        (filters.birthdayMin === "" ||
            new Date(item.applicantDateOfBirth) >= new Date(filters.birthdayMin)) &&
        (filters.birthdayMax === "" ||
            new Date(item.applicantDateOfBirth) <= new Date(filters.birthdayMax)) &&
         item.projectName.toLowerCase().includes(filters.projectName.toLowerCase()) &&
         item.projectIndustry.toLowerCase().includes(filters.industry.toLowerCase()) &&
        item.projectMunicipality.toLowerCase().includes(filters.municipality.toLowerCase()) &&
        item.projectRegion.toLowerCase().includes(filters.region.toLowerCase()) &&
        item.projectVillage.toLowerCase().includes(filters.village.toLowerCase()) &&
        item.ApplicantIdentificationCode.toLowerCase().includes(filters.entityID.toLowerCase()) 
    
    );
});



    setData({
      loading: false,
      data: filtered,
      error: false,
    });
  };




  const resetFilters = () => {
    setFilters({
      ...filters,
  
    });

    setData({
      loading: false,
      data: original,
      error: false,
    });
  };

  return (
    <section className="w-full p-2 border-b-2 shadow-lg ">

<div className="flex flex-col gap-3 w-full p-2 relative h-fit mt-8">
  <label
    className="font-normal text-gray-500 bg-white z-20 ml-3 absolute t pl-2 pr-2 left-2"
   htmlFor="applicationNumber"> საიდენტიფიკაციო კოდი </label>
  <input
  className="w-full, p-2 border bg-white  border-gray-500 focus:outline-none pt-3 "
   type='number' name='applicationNumber' id="applicationNumber"  value={applicationId} onChange={(e) => setApplicationId(e.target.value)} />

   <p onClick={handleSearchWithApplicationId} className="border-b pb-5 cursor-pointer text-textColor hover:text-headerColor  font-bold text-lg">ძებნა საიდენტიფიკაციო კოდით</p>
</div>


      <div className="w-full mt-3 grid grid-cols-3 gap-2 border-b pb-3">
        {Object.entries(filters).map(([key, value],index) => (
          <div key={key}>
            <CommonInput
              type={key.startsWith("birthday") ? "date" : "text"}
              name={key}
              value={value}
              id={key}
              label={labels[index]}
              handleChange={(e) => handleChange(e)}
            />
          </div>
        ))}
      </div>



      <div className="flex justify-between mt-4 px-5">
        <button
          onClick={applyFilters}
          className="bg-textColor hover:bg-sky-700 text-white font-bold py-2 px-4 rounded transition-all duration-500 ease-in-out"
        >
          გაფილტრვა
        </button>
        <button
          onClick={resetFilters}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition-all duration-500 ease-in-out"
        >
          დარესეტება
        </button>
      </div>
    </section>
  );
};

export default Filters;
