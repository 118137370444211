import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import { useNavigate } from "react-router-dom";

const data = [
  {
    text: "განაცხადები",
    icon: <ManageHistoryIcon sx={{ fontSize: "40px" }} />,
    to: "/applications/all",
  },
  {
    text: " USAID-CNFA განაცხადები",
    icon: <ManageHistoryIcon sx={{ fontSize: "40px" }} />,
    to: "/applications/usaid",
  },
  {
    text: "ბოლოს შემოსული განაცხადები",
    icon: <MarkEmailUnreadIcon sx={{ fontSize: "40px" }} />,
    to: "/applications/new",
  },
];


const MainAdminHome = () => {
    const navigate = useNavigate();
    return (
      <main className="grid grid-cols-2 gap-8 mt-10 p-5">
        {data.map((item, index) => {
          return (
            <div
              onClick={() => navigate(item.to)}
              key={index}
              className="min-h-60 p-10 text-gray-500 font-bold flex gap-5 bg-gray-300 justify-center items-center cursor-pointer aft "
            >
              <h2 className="text-2xl">{item.icon}</h2>
              <h2 className="text-2xl font-bold">{item.text}</h2>
            </div>
          );
        })}
      </main>
    )
}


export default MainAdminHome