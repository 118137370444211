import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const Table = ({ handleModal, news, handleClick }) => {
  return (
    <div className="overflow-x-auto w-full ">
      <table className="min-w-full divide-y divide-gray-200 w-full">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 pt-5  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r "
              style={{
                minWidth: "100px",
              }}
            >
              წაშლა
            </th>
            <th
              scope="col"
              className="px-6 py-3 pt-5  text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r "
              style={{ minWidth: "100px" }}
            >
              დაედითება
            </th>
            <th
              scope="col"
              className="px-6 py-3 pt-5  text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r "
              style={{ minWidth: "200px" }}
            >
              სურათი
            </th>
            <th
              scope="col"
              className="px-6 py-3 pt-5  text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
            >
              თარიღი
            </th>
            <th
              scope="col"
              className="px-6 py-3 pt-5  h-auto text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
            >
              სათაური
            </th>
            <th
              scope="col"
              className="px-6 py-3 pt-5  text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
            >
              ლინკი
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 ">
          {news.map((item) => (
            <tr key={item.id}>
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                <button
                  type="button"
                  onClick={() => handleModal(item.id)}
                  className="text-red-600 hover:text-red-900"
                >
                  <DeleteIcon />
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                <button
                  type="button"
                  onClick={() => handleClick(item.id)}
                  className="text-textColor hover:text-sky-800 flex items-center justify-center "
                >
                  <EditIcon />    ინფორმაციის შეცვლა

                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                <img
                  className="w-48 h-32 object-cover"
                  src={item.img}
                  alt={`${item.title}_img`}
                />
              </td>
              
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                {item.date}
              </td>
              <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                {item.title}
              </td>
              <td className={`px-6 py-4 whitespace-nowrap border-b border-r ${item.more ? "text-red-400 hover:text-red-500 cursor-pointer" : "text-black"}`}>
                {item.more ? <a>{item.more}</a> : "არ აქვს ლინკი"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;