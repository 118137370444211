import AddCircleIcon from "@mui/icons-material/AddCircle";

export const CommonEditInput = ({
  type,
  name,
  value,
  id,
  label,
  handleChange,
  icon,
  handleAdd,
}) => {
  return (
    <div className="flex flex-col gap-3 w-full p-2 relative h-fit">
      <label
        className="font-normal text-gray-500 bg-neutral-100 z-20 ml-3 absolute t pl-2 pr-2 left-2"
        htmlFor={id}
      >
        {label}
      </label>
      <input
        className="w-full, p-2 border bg-neutral-100  border-gray-500 focus:outline-none pt-3 "
        type={type}
        id={id}
        value={value}
        name={name}
        onChange={handleChange}
      />
      {icon && (
        <span className="absolute right-4 top-4">
          <AddCircleIcon
            onClick={handleAdd}
            sx={{
              cursor: "pointer",
              color: "gray",
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                color: "green",
              },
            }}
          />
        </span>
      )}
    </div>
  );
};

export const CommonEditTextArea = ({
  name,
  value,
  id,
  label,
  handleChange,
}) => {
  return (
    <div className="flex flex-col gap-3 w-full p-2 relative h-fit">
      <label
        className="font-normal text-gray-500 bg-neutral-100 z-20 ml-3 absolute t pl-2 pr-2 left-2"
        htmlFor={id}
      >
        {label}
      </label>
      <textarea
        className="w-full, p-2 border bg-neutral-100  border-gray-500 focus:outline-none pt-3 "
        id={id}
        value={value}
        name={name}
        rows={5}
        onChange={handleChange}
      />
    </div>
  );
};