import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { baseAPI } from "../../Services/baseApi";

export const SingleApplication = () => {
  const { id } = useParams();

  const [application, setApplication] = useState({
    loading: true,
    data: {},
    error: false,
  });

  useEffect(() => {
    baseAPI
      .get(`applications/${id}`)  
      .then((res) => {
        setApplication((prev) => ({
          ...prev,
          data: res.data,
          error: false,
        }));
    
      })

      .catch((err) => {
        setApplication({
          loading: false,
          data: {},
          error: true,
        });
      })
      .finally(() => {
        setApplication((prev) => ({ ...prev, loading: false }));
      });
  }, [id]);

  if (application.loading) {
    return <h1>Loading....</h1>;
  }
  if (application.error || !application.data) {
    return <h1>Error</h1>;
  }

  const { data } = application;


  const aplicant = [
    {
      name: "აპლიკაციის ნომერი",
      value: data.applicationNumber,
    },
    {
      name: "სახელი",
      value: data.applicantName,
    },
    {
      name: "გვარი",
      value: data.applicantLastName,
    },
    {
      name: "სქესი",
      value: data.applicantGender,
    },
    {
      name: "ელ-ფოსტა",
      value: data.applicantEmail,
    },
    {
      name: "მობილურის ნომერი",
      value: data.applicantPhone,
    },
    {
      name: "პირადი ნომერი",
      value: data.applicantIdNumber,
    },
    {
      name: "დაბადების თარიღი",
      value: data.applicantDateOfBirth,
    },
    {
      name: "მისამართი",
      value: data.applicantAddress,
    },
  ];

  const projectInfo = [
    {
      name: "სახელი",
      value: data.projectName,
    },
    {
      name: "ინდუსტრია",
      value: data.projectIndustry,
    },
    {
      name: "მუნიციპალიტეტი",
      value: data.projectMunicipality,
    },
    {
      name: "რეგიონი",
      value: data.projectRegion,
    },
    {
      name: "სოფელი",
      value: data.projectVillage,
    },
    {
      name: "იურიდიული პირის საინდენტიფიკაციო კოდი",
      value: data.ApplicantIdentificationCode,
    },
    {
      name: "იურიდიული პირის დაბადების თარიღი",
      value: data.ApplicantBday,
    },
  ];

  const budjet = [
    {
      name: "ფონდისგან მოთხოვნილი თანადაფინანსება",
      value: data.projectBudjetFromStartupGeorgia,
    },
    {
      name: "პროექტის ჯამური ფულადი ბიუჯეტი",
      value: data.projectTotalBudjet,
    },
    {
      name: "ავტორის ფულადი მონაწილეობა",
      value: data.projectBudjetFromApplicant,
    },
    {
      name: "არაფულადი შენატანის ღირებულება",
      value: data.projectExistingBudjet,
    },
    {
      name: "USAID ZRDA გრანტი",
      value: data.projectBudjetFromUsaid,
    },
  ];

  const descs = [
    {
      name: "პროექტის მოკლე აღწერა",
      value: data.projectDescription,
    },
    {
      name: " ინოვაციური ელემენტები პროექტში",
      value: data.projectInnovationElement,
    },
    {
      name: "სამიზნე ბაზრის აღწერა",
      value: data.projectTargetAudience,
    },
    {
      name: "აპლიკანტის და გუნდის წევრების აღწერა",
      value: data.projectMembersDesc,
    },
    {
      name: "შემოსავლების აღწერა",
      value: data.projectIncomeDesc,
    },
  ];

  return (
    <main className="w-full p-3">
      <section className="w-full p-2 flex flex-col gap-0">
        <h2 className="text-2xl text-textColor font-bold border-b pb-2 mb-5 pl-2">
          აპლიკანტი
        </h2>
        {aplicant.map((item, index) => (
          <article
            key={index}
            className="w-full flex items-center gap-5 border "
          >
            <div className="w-2/6 p-2 border-r">
              <h2 className="ml-5 font-semibold">{item.name}</h2>
            </div>
            <div className="w-4/6 p-2">{item.value}</div>
          </article>
        ))}
      </section>
      <section className="w-full p-2 flex flex-col gap-0 mt-5">
        <h2 className="text-2xl text-textColor font-bold border-b pb-2 mb-5 pl-2">
          პროექტის დეტალები
        </h2>
        {projectInfo
          .map((item, index) => (
            <article
              className="w-full flex items-center gap-5 border"
              key={index}
            >
              <div className="w-2/6 p-2 border-r">
                <h2 className="ml-5 font-semibold">{item.name}</h2>
              </div>
              <div className="w-4/6 p-2">{item.value}</div>
            </article>
          ))
          .slice(
            0,
            data.isNew === 1 ? projectInfo.length : projectInfo.length - 2
          )}
      </section>
      <section className="w-full p-2 flex flex-col gap-0 mt-5">
        <h2 className="text-2xl text-textColor font-bold border-b pb-2 mb-5 pl-2">
          ბიუჯეტი
        </h2>
        {budjet
          .map((item, index) => (
            <article
              className="w-full flex items-center gap-5 border"
              key={index}
            >
              <div className="w-2/6 p-2 border-r">
                <h2 className="ml-5 font-semibold">{item.name} </h2>
              </div>
              <div className="w-4/6 p-2">{item.value} ₾</div>
            </article>
          ))
          .slice(0, data.isUsaid === 1 ? budjet.length : budjet.length - 1)}
      </section>
      <section className="w-full p-2 flex flex-col gap-0 mt-5">
        <h2 className="text-2xl text-textColor font-bold border-b pb-2 mb-5 pl-2">
          პროექტის აღწერა
        </h2>
        {descs.map((item, index) => (
          <article
            className="w-full flex items-center gap-5 border"
            key={index}
          >
            <div className="w-2/6 p-2 border-r">
              <h2 className="ml-5 font-semibold">{item.name}</h2>
            </div>
            <div className="w-2/6 p-2 ">
              <h2 className="ml-5 font-semibold">{item.value}</h2>
            </div>
          </article>
        )) .slice(
          0,
          data.isUsaid === 1 ? descs.length : descs.length - 3
        )}
      </section>

   {data.files.length > 0 && (
        <section className="w-full p-2 flex flex-col gap-0 mt-5">
        <h2 className="text-xl text-textColor font-bold border-b pb-2 mb-5 pl-2">
          ატვირთული ფაილები
        </h2>

       <div className="flex gap-3 flex-wrap font-medium text-red-400">
       
        {  data.files.map((item, index) => {
            return <a target="_blank rel=noreferrer" className="cursor-pointer hover:text-headerColor" key={index} href={item}>file</a>
          })}
       </div>
      </section>
   )}

      {data.members.length > 0 && (
        <div className="w-full  flex flex-col gap-4 mt-5">
          <h2 className="text-2xl text-textColor font-bold border-b pb-2 pl-2 ">
            ჯგუფის წევრები
          </h2>
          <div className="overflow-x-auto mt-4">
            <table className="min-w-full divide-y divide-gray-200 w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b"
                  >
                    სახელი
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
                  >
                    გვარი
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
                  >
                    ელ-ფოსტა
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
                  >
                    როლი
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
                  >
                    მობილურის ნომერი
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
                  >
                    პირადი ნომერი
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
                  >
                    დაბადების თარიღი
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r"
                  >
                    LinkedIn
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 ">
                {data.members.map((member, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                      {member.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                      {member.lastname}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                      {member.email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                      {member.position}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                      {member.phone}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                      {member.idnumber}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                      {member.birthday}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap border-b border-r">
                      {member.linkedin}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </main>
  );
};


