import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Pages/HomePage/Home";
import AllAplications from "./Pages/AllApplications/AllAplications";
import UsaidApplications from "./Pages/UsaidApplications/UsaidApplications";
import Header from "./Header/Header";
import Login from "./LogIn/Login";
import { jwtDecode } from "jwt-decode";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { handleSignIn } from "./Services/useSlice";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import AllNews from "./Pages/AllNews.jsx/AllNews";
import AddNews from "./Pages/AddNews.jsx/AddNews";
import AllWinners from "./Pages/AllWinners/AllWinners";
import AddWinner from "./Pages/AddWinners/AddWinner";
import SingleNews from "./Pages/AllNews.jsx/SingleNews";
import SingleWinner from "./Pages/AllWinners/SingleWinner";
import NewApplications from "./Pages/NewApplications/NewApplications";
import {SingleApplication} from './Pages/SingleApplication/SingleApplication'


function App() {


  const dispatch = useDispatch();

  useMemo(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        if (decoded.exp * 1000 < Date.now()) {
          return false;
        }

        dispatch(handleSignIn(decoded));
        return true;
      } catch (e) {
        return false;
      }
    }

    return false;
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route element={<ProtectedRoute/>}>
          <Route path="/applications/all" element={<AllAplications />} />
          <Route path="/applications/usaid" element={<UsaidApplications />} />
          <Route path="/application/:id" element={<SingleApplication />} />
          <Route path="/news/all" element={<AllNews/>} />
          <Route path="/news/:id" element={<SingleNews/>} />
          <Route path="/news/add" element={<AddNews />} />
          <Route path="/winners/all" element={<AllWinners/>} />
          <Route path="/winners/add" element={<AddWinner/>} />
          <Route path="/winners/:id" element={<SingleWinner/>} />
          <Route path="/applications/new" element={<NewApplications/>} />
          <Route path="/" element={<Home />} />
          </Route>
      
          <Route path="/login" element={<Login/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
