import { Field, ErrorMessage } from "formik";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const CommonFormikInput = ({ htmlfor, label, name, type, rows, icon, handleAdd }) => {
  return (
    <div className="flex flex-col gap-3 w-full p-2 relative h-fit">
      <label
        className="font-normal text-gray-500 bg-neutral-100 z-20 ml-3 absolute t pl-2 pr-2 left-2"
        htmlFor={htmlfor}
      >
        {label}
      </label>
      <Field
        as={type}
        type={type}
        name={name}
        id={htmlfor}
        rows={rows}
        className="w-full, p-2 border bg-neutral-100  border-gray-500 focus:outline-none pt-3 "
      />
      <p className="text-red-500 text-sm ml-3">
        <ErrorMessage name={name} />
      </p>

      {icon && (
        <span className="absolute right-4 top-4">
          <AddCircleIcon
            onClick={handleAdd}
            sx={{
              cursor: "pointer",
              color: "gray",
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                color: "green",
              },
            }}
          />
        </span>
      )}
    </div>
  );
};

export default CommonFormikInput;