export const CommonInput = ({ type, name, value, id, label, handleChange }) => {
  return (
    <div className="flex flex-col gap-3 w-full p-2 relative h-fit">
      <label
        className="font-normal text-gray-500 bg-white z-20 ml-3 absolute t pl-2 pr-2 left-2"
        htmlFor={id}
      >
        {label}
      </label>
      <input
        className="w-full, p-2 border bg-white  border-gray-500 focus:outline-none pt-3 "
        type={type}
        id={id}
        value={value}
        name={name}
        onChange={handleChange}
      />
    </div>
  );
};
