import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { baseAPI } from "../../Services/baseApi";
import { CommonEditInput, CommonEditTextArea } from "../../Components/CommonEditInput";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { storage } from "../../Utils/firebase";
import CommonModal from "../../Components/CommonModal";

const SingleNews = () => {
  const [originalNews, setOriginalNews] = useState({
    isLoading: true,
    data: {},
    error: false,
  });
  const [image, setImage] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [success, setSuccess] = useState(false);

  const { id } = useParams();

  const [updatedNews, setUpdatedNews] = useState({
    title: "",
    desc: "",
    desc2: "",
    desc3: "",
    desc4: "",
    desc5: "",
    more: "",
    date: "",
  });

  const handleUpdateChange = (event) => {
    setUpdatedNews({
      ...updatedNews,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    baseAPI
      .get(`/news/${id}`)
      .then((res) => {
        setOriginalNews({
          isLoading: false,
          data: res.data,
          error: false,
        });
      })
      .catch((err) => {
        setOriginalNews({
          isLoading: false,
          data: {},
          error: true,
        });
      });
  }, [id]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    setNewImage(file);

    reader.onload = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpload = async (file) => {
    setLoading(true);
    const imageRef = ref(storage, `news/${file.name + v4()}`);
    try {
      const snapshot = await uploadBytes(imageRef, file);
      const downloadUrl = await getDownloadURL(snapshot.ref);
      console.log("Firebase Storage download URL:", downloadUrl); // Log the download URL
      return downloadUrl;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw new Error("Failed to upload image");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      let imageUrl = originalNews.data.img;
      if (newImage) {
        imageUrl = await handleImageUpload(newImage);
      }

      const updatedData = {
        ...originalNews.data,
        title: updatedNews.title || originalNews.data.title,
        description: updatedNews.desc || originalNews.data.desc,
        desc2: updatedNews.desc2 || originalNews.data.desc2,
        desc3: updatedNews.desc3 || originalNews.data.desc3,
        desc4: updatedNews.desc4 || originalNews.data.desc4,
        desc5: updatedNews.desc5 || originalNews.data.desc5,
        more: updatedNews.more || originalNews.data.more,
        date: updatedNews.date || originalNews.data.date,
        img: imageUrl,
      };

      const response = await baseAPI.put(`/news/update/${id}`, updatedData);
      if (response.status === 200) {
        setSuccess(true);
        setTimeout(() => {
          setModal(false);
        }, 1000);
      }
    } catch (error) {
      console.error("Error updating news:", error);
      alert("Failed to update news");
    }
  };

  const handleModal = () => {
    setModal(true);
    setSuccess(false);
  };

  const labels = [
    "სათაური",
    "აბზაცი 1",
    "აბზაცი 2",
    "აბზაცი 3",
    "აბზაცი 4",
    "აბზაცი 5",
    "ლინკი",
    "თარიღი",
  ];

  if (originalNews.isLoading) {
    return <div>Loading...</div>;
  }
  if (originalNews.error) {
    return <div>Error Loading News</div>;
  }

  return (
    <main className="w-11/12 ml-12">
      <div className="w-full flex gap-3 mt-3 border-b pb-2 rounded-sm">
        <div className="flex flex-col gap-3 w-1/2 p-2">
          <label className="font-bold ml-2" htmlFor="file">
            სურათის შეცვლა
          </label>
          <input
            className="w-full, p-2 border "
            type="file"
            id="file"
            name="img"
            onChange={handleFileChange}
          />
        </div>
        <div className="border-2 flex w-1/2 h-80">
          <img
            src={image ? image : originalNews.data.img}
            alt="Uploaded Img"
            className="h-full w-full object-cover"
          />
        </div>
      </div>

      {Object.keys(updatedNews).map((key, index) => {
        if (key === "desc" || key === "desc2" || key === "desc3" || key === "desc4" || key === "desc5") {
          return (
            <CommonEditTextArea
              key={key}
              name={key}
              value={
                updatedNews[key] !== ""
                  ? updatedNews[key]
                  : originalNews.data[key]
              }
              id={key}
              label={labels[index]}
              handleChange={handleUpdateChange}
            />
          );
        } else if (key === "date") {
          return (
            <CommonEditInput
              key={key}
              type="date"
              name={key}
              value={
                updatedNews[key] !== ""
                  ? updatedNews[key]
                  : originalNews.data[key]
              }
              id={key}
              label={labels[index]}
              handleChange={handleUpdateChange}
            />
          );
        } else if (key !== "img") {
          return (
            <CommonEditInput
              key={key}
              type="text"
              name={key}
              value={
                updatedNews[key] !== ""
                  ? updatedNews[key]
                  : originalNews.data[key]
              }
              id={key}
              label={labels[index]}
              handleChange={handleUpdateChange}
            />
          );
        }

        return null;
      })}

      <button
        className="bg-textColor text-white p-3 mb-6 ml-5 mt-3 rounded-xl font-bold hover:opacity-90"
        disabled={loading}
        onClick={handleModal}
      >
        {loading ? "აფდეითდება..." : "შეცვლა"}
      </button>

      {modal && (
        <CommonModal
          success={success}
          handleSubmit={() => handleSubmit(id)}
          setModal={setModal}
          successText=" სიახლე წარმატებით დააფდეითდა !!"
          question=" დარწმუნებული ხართ რომ გინდათ ცვლილებების შენახვა?"
        />
      )}
    </main>
  );
};

export default SingleNews;