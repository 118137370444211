import { Formik, Form, ErrorMessage } from "formik";
import CommonFormikInput from "../../Components/CommonFormikInput";
import * as Yup from "yup";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useState } from "react";
import { v4 } from "uuid";
import { baseAPI } from "../../Services/baseApi";
import { storage } from "../../Utils/firebase";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  desc2: Yup.string(),
  desc3: Yup.string(),
  more: Yup.string(),
  img: Yup.mixed().required("Image is required"),
});

const AddNews = () => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpload = (file) => {
    setLoading(true);
    const imageRef = ref(storage, `news/${file.name + v4()}`);
    return uploadBytes(imageRef, file)
      .then(() => getDownloadURL(imageRef))
      .finally(() => setLoading(false));
  };

  const handleDateChange = (event, setFieldValue) => {
    setFieldValue("date", event.target.value);
  };

  return (
    <main className="bg-neutral-100">
      <Formik
        initialValues={{
          title: "",
          description: "",
          desc2: "",
          desc3: "",
          desc4: "",
          desc5: "",
          more: "",
          date: "",
          img: null,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          const currentDate = values.date
            ? values.date
            : new Date().toISOString().split("T")[0];
          handleImageUpload(values.img)
            .then((downloadUrl) => {
                const formData={
                    title: values.title,
                    description: values.description,
                    desc2: values.desc2,
                    desc3: values.desc3,
                    desc4: values.desc4,
                    desc5: values.desc5,
                    more: values.more,
                    date: currentDate,
                    img: downloadUrl,
                }
               console.log(formData)
              return baseAPI.post("news/addnews", formData
            );
            })
            .then((res) => {
                console.log(res);
              if (res) {
                resetForm();
                setImage(null);
                alert("News added successfully");
              }
            })
            .catch((error) => {
              console.error("Error adding news:", error);
              alert("Failed to add news");
            });
        }}
      >
        {({ setFieldValue, values }) => (
        
          <div className="flex w-full justify-center  flex-col items-center min-h-screen">
            <Form className="flex mt-5 mb-3 flex-col p-3 gap-5 w-full self-center   ">
              <div className="w-full flex gap-3 mt-3 border-b pb-2 rounded-sm">
                <div className="flex flex-col gap-3 w-1/2 p-2">
                  <label className="font-bold ml-2" htmlFor="file">
                    სურათის დამატება
                  </label>
                  <input
                    className="w-full, p-2 border "
                    type="file"
                    id="file"
                    name="img"
                    onChange={(event) => {
                      setFieldValue("img", event.currentTarget.files[0]);
                      handleFileChange(event);
                    }} 
                  />

                  <ErrorMessage
                    name="img"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="border-2 flex w-1/2 h-60">
                  {image && (
                    <img
                      src={image}
                      alt="Uploaded Img"
                      className="h-full w-full object-cover"
                    />
                  )}
                </div>
              </div>
              <div className="w-full flex gap-3 border-b pb-2 rounded-sm">
                <CommonFormikInput type="" label="სათაური" name="title" />

                <div className="flex flex-col gap-3 w-full p-2 relative h-fit">
                  <label
                    className="font-normal text-gray-500 bg-neutral-100 z-20 ml-3 absolute t pl-2 pr-2 left-2"
                    htmlFor="date"
                  >
                    თარიღი :
                  </label>
                  <input
                    type="date"
                    id="date"
                    name="date"
                    onChange={(event) => handleDateChange(event, setFieldValue)}
                    className="w-full, p-2 border bg-neutral-100  border-gray-500 focus:outline-none pt-3 "
                    value={values.date}
                  />
                </div>
              </div>
              <div className="w-full flex gap-3 border-b pb-2 rounded-sm">
                <CommonFormikInput
                  type="textarea"
                  label="აბზაცი 1"
                  name="description"
                  rows={5}
                />
                <CommonFormikInput
                  type="textarea"
                  label="აბზაცი 2"
                  name="desc2"
                  rows={5}
                />
              </div>

              <div className="w-full flex gap-3 border-b pb-2 rounded-sm">
                <CommonFormikInput
                  type="textarea"
                  label="აბზაცი 3"
                  name="desc3"
                  rows={5}
                />
                <CommonFormikInput
                  type="textarea"
                  label="აბზაცი 4"
                  name="desc4"
                  rows={5}
                />
              </div>
              <div className="w-full flex gap-3 border-b pb-2 rounded-sm">
                <CommonFormikInput
                  type="textarea"
                  label="აბზაცი 5"
                  name="desc5"
                  rows={5}
                />
                <CommonFormikInput type="" label="ლინკი" name="more" />
              </div>

              <div className="w-full flex justify-end items-end ">
                <button
                  type="submit"
                  className="bg-textColor text-white p-3 mb-6 mr-5 rounded-xl font-bold hover:opacity-90"
                  disabled={loading}
                >
                  {loading ? "სიახლე ემატება..." : "დამატება"}
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </main>
  );
};

export default AddNews;
