import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { handleSignOut } from "../Services/useSlice";


const Header = () => {
  const { isLoggedIn} = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const navigate = useNavigate();

  const onSignOut = () => {
    dispatch(handleSignOut());
    navigate("/login");
  }

  useEffect(() => {
    if (!isLoggedIn) setText("");
  }, [isLoggedIn]);





  return (
    <header className="p-3 w-full bg-textColor text-white flex justify-between items-center">
      <figure onClick={() => navigate("/")} className="pl-4 cursor-pointer">
        <img src="/logo.png" alt="logo" />
      </figure>

      <h1 className="text-2xl text-white font-semibold">{text}</h1>

      <div className=" flex gap-4 text-lg font-semibold pr-4 cursor-pointer items-center ">
        <AccountCircleIcon sx={{ fontSize: "40px" }} />
      {isLoggedIn &&  <span onClick={onSignOut}>გასვლა</span>}
      </div>
    </header>
  );
};

export default Header;
