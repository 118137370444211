import { useState, useEffect } from "react";
import { baseAPI } from "../../Services/baseApi";
import Table from "../../Components/Table";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import Filters from "../../Components/CommonFilters";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";

const AllAplications = () => {
  const [applications, setApplications] = useState({
    loading: true,
    data: [],
    error: false,
  });

  const [original, setOriginal] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const navigate=useNavigate()



  useEffect(() => {
    baseAPI
      .get("applications/allapplications")
      .then((res) => {
        const notUsaid = res.data.filter((item) => item.isUsaid === 0);

        const sorted = notUsaid.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        setOriginal(sorted);

        setApplications({
          loading: false,
          data: sorted,
          error: false,
        });
      })
      .catch((err) => {
        setApplications({
          loading: false,
          data: [],
          error: true,
        });
      });
  }, []);





  const handleIsNewFilter=()=>{
    const filtered = original.filter((item) => {
      return (
        item.isNew
      );
    });

    setApplications({
      loading: false,
      data: filtered,
      error: false,
    })
  }


  const handleFileFilter = () => {
    const filtered = original.filter((item) => {
      return (
       item.files && item.files.length>0
      );
    });

    setApplications({
      loading: false,
      data: filtered,
      error: false,
    })
  }


  const handleNofilesFilter = () => {
    const filtered = original.filter((item) => {
      return (
        !item.files || item.files.length === 0
      );
    });

    setApplications({
      loading: false,
      data: filtered,
      error: false,
    })
  
  }
  const handleMale = () => {
    const filtered = original.filter((item) => {
      return (
        item.applicantGender === "კაცი"
      );
    });

    setApplications({
      loading: false,
      data: filtered,
      error: false,
    })
  
  }
  const handleFemale = () => {
    const filtered = original.filter((item) => {
      return (
        item.applicantGender === "ქალი"
      );
    });

    setApplications({
      loading: false,
      data: filtered,
      error: false,
    })
  
  }
  const handleNonBinary = () => {
    const filtered = original.filter((item) => {
      return (
        item.applicantGender === "არ მსურს მითითება"
      );
    });

    setApplications({
      loading: false,
      data: filtered,
      error: false,
    })
  
  }

  const handleResetFilter = () => {
    setApplications({
      loading: false,
      data: original,
      error: false,
    })
  }

  const handleClick = (id) => {
    navigate(`/application/${id}`);
  };

  const keysToDelete = ['isNew', 'isUsaid', 'hasAgreed','id', 'projectMembersDesc', 'projectIncomeDesc','projectTargetAudience','projectBudjetFromUsaid'];
  const keyMapping = {
    applicationNumber: 'აპლიკაციის ნომერი',
    applicantName: 'სახელი',
    applicantLastName: 'გვარი',
    applicantGender: 'სქესი',
    applicantEmail: 'ელ ფოსტა',
    applicantIdNumber: 'პირადი ნომერი',
    applicantAddress: 'მისამართი',
    applicantPhone: 'მობილურის ნომერი',
    applicantDateOfBirth: 'დაბადების თარიღი',
    projectName: 'პროექტის სახელი',
    projectIndustry: 'ინდუსტრია',
    projectRegion: 'რეგიონი',
    projectMunicipality: 'მუნიციპლიტეტი',
    projectVillage: 'სოფელი',
    ApplicantIdentificationCode: 'იურიდიული პირის საიდენტიფიკაციო კოდი',
    ApplicantBday: 'დაარსების თარიღი',
    projectTotalBudjet: 'პროექტის ჯამური ბიუჯეტი',
    projectBudjetFromStartupGeorgia: 'სტარტაპისგან მოთხოვნილი ბიუჯეტი',
    projectBudjetFromApplicant: 'ავტორის ფულადი მონაწილეობა',
    projectExistingBudjet: 'არაფულადი შენატანის ღირებულება',
    projectDescription: 'პროექტის აღწერა',
    projectInnovationElement: 'ინოვაციური_ელემენტი',
    members: 'ჯგუფის_წერვრები',
    files: 'ატვირთული ფალების ლინკები',
    createdAt: 'განაცხადის შემოსვლის თარიღი',
  };

const downloadXL=()=>{
  const wb=XLSX.utils.book_new();

  const modifiedData = applications.data.map(item => {
    // Create a copy of the item object
    const modifiedItem = {};

    // Rename keys and copy values
    Object.keys(item).forEach(key => {
      if (keyMapping[key]) {
        modifiedItem[keyMapping[key]] = item[key];
      } else if (!keysToDelete.includes(key)) {
        modifiedItem[key] = item[key];
      }
    });

    return modifiedItem;
  });


  const ws=XLSX.utils.json_to_sheet(modifiedData);
  XLSX.utils.book_append_sheet(wb,ws,"Applications");
  XLSX.writeFile(wb,"applications.xlsx");
}



  if (applications.loading) {
    return <h1>Loading....</h1>;
  }
  if (applications.error || !applications.data) {
    return <h1>Error</h1>;
  }

  return (
    <main className="relative pb-8">
        <div
        onClick={() => setShowFilters(!showFilters)}
        className="absolute top-0 left-5  rounded-lg text-lg font-bold z-40 "
      >
        {showFilters ? (
          <KeyboardDoubleArrowUpIcon />
        ) : (
          <KeyboardDoubleArrowDownIcon />
        )}
      </div>
      {showFilters && (
        <Filters
          data={applications.data}
          setData={setApplications}
          original={original}
        />
      )}
      <div className="w-full text-xl flex justify-between items-center font-semibold text-textColor border-b pb-2 pl-5 pt-10 mt-5">
        <h2>ნაპოვნია {applications.data.length} განცხადება</h2>
         <button className="text-green-600 mr-10 hover:opacity-80" onClick={downloadXL}>გადმოწერე XL ფაილი </button>
      </div>
<ul className="flex gap-4  text-textColor p-2  font-semibold text-sm">
<li
onClick={handleResetFilter}
 className="cursor-pointer border-r hover:opacity-80 pr-2">ყველა განაცხადი</li>
<li
onClick={handleIsNewFilter}
 className="cursor-pointer border-r hover:opacity-80 pr-2" > იურიდიული პირი არის არსებული</li>
<li 
onClick={handleFileFilter}
className="cursor-pointer border-r hover:opacity-80 pr-2"> თან აქვს ფაილები</li>
<li
onClick={handleNofilesFilter}
 className="cursor-pointer hover:opacity-80 border-r pr-2 " >თან არ აქვს აქვს ფაილები</li>
<li
onClick={handleFemale}
 className="cursor-pointer hover:opacity-80 border-r pr-2 " >ქალები</li>
<li
onClick={handleMale}
 className="cursor-pointer hover:opacity-80  border-r pr-2" >კაცები</li>
<li
onClick={handleNonBinary}
 className="cursor-pointer hover:opacity-80 " >სქესი არ არის მითითებული</li>

</ul>
      <Table applications={applications.data} handleClick={handleClick} />
    </main>
  );
};

export default AllAplications;
