import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import { useNavigate } from "react-router-dom";

const data = [
  {
    text: "სიახლეების დამატება",
    icon: <AddCircleOutlineIcon sx={{ fontSize: "40px" }} />,
    to: "/news/add",
  },
  {
    text: "სიახლეების მართვა",
    icon: <ManageHistoryIcon sx={{ fontSize: "40px" }} />,
    to: "/news/all",
  },
  {
    text: "გამარჯვებულების დამატება",
    icon: <AddCircleOutlineIcon sx={{ fontSize: "40px" }} />,
    to: "/winners/add",
  },
  {
    text: "გამარჯვებულების მართვა",
    icon: <ManageHistoryIcon sx={{ fontSize: "40px" }} />,
    to: "/winners/all",
  },
];

const EditAdminHome = () => {
  const navigate = useNavigate();
  return (
    <main className="grid grid-cols-2 gap-8 mt-10 p-5">
      {data.map((item, index) => {
        return (
          <div
            onClick={() => navigate(item.to)}
            key={index}
            className="min-h-60 p-10 text-gray-500 font-bold flex gap-5 bg-gray-300 justify-center items-center cursor-pointer aft "
          >
            <h2 className="text-2xl">{item.icon}</h2>
            <h2 className="text-2xl font-bold">{item.text}</h2>
          </div>
        );
      })}
    </main>
  );
};

export default EditAdminHome;
