import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { storage } from "../../Utils/firebase";
import { baseAPI } from "../../Services/baseApi";
import { CommonEditInput, CommonEditTextArea } from "../../Components/CommonEditInput";
import CommonModal from "../../Components/CommonModal";

const SingleWinner = () => {
  const { id } = useParams();
  const [originalWinner, setOriginalWinner] = useState({
    isLoading: true,
    data: {},
    error: false,
  });
  const [image, setImage] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [memberArray, setMemberArray] = useState([]);
  const [listArray, setListArray] = useState([]);

  const [updatedWinner, setUpdatedWinner] = useState({
    projectName: "",
    projectDesc: "",
    city: "",
    img: "",
    totalBudget: 0,
    budjetFromStartupGeorgia: 0,
    Date: "",
    listName: "",
    groupMembers: [],
    member: "",
    listItem: "",
    listItems: [],
  });



  const handleUpdateChange = (event) => {
    setUpdatedWinner({
      ...updatedWinner,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    baseAPI
      .get(`winners/${id}`)
      .then((res) => {
        setOriginalWinner({
          isLoading: false,
          data: res.data,
          error: false,
        });
        setMemberArray(res.data.groupMembers);
        setListArray(res.data.listItems);
      })
      .catch((err) => {
        setOriginalWinner({
          isLoading: false,
          data: {},
          error: true,
        });
      });
  }, [id]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    setNewImage(file);

    reader.onload = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpload = async (file) => {
    setLoading(true);
    const imageRef = ref(storage, `projects/${file.name + v4()}`);
    try {
      const snapshot = await uploadBytes(imageRef, file);
      const downloadUrl = await getDownloadURL(snapshot.ref);
      console.log("Firebase Storage download URL:", downloadUrl); 
      return downloadUrl;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw new Error("Failed to upload image");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      let imageUrl = originalWinner.data.img;
      if (newImage) {
        imageUrl = await handleImageUpload(newImage);
      }
      const { id, ...restData } = originalWinner.data;
      const updatedData = {
        ...restData,
        winnerName:
          updatedWinner.projectName || originalWinner.data.winnerName ,
        projectDesription:
          updatedWinner.projectDesc || originalWinner.data.projectDesription,
        city: updatedWinner.city || originalWinner.data.city,
        budjetFromStartupGeorgia:
        parseFloat(updatedWinner.budjetFromStartupGeorgia)  ||
        parseFloat(originalWinner.data.budjetFromStartupGeorgia),
        totalBudjet:
          parseFloat(updatedWinner.totalBudget) || parseFloat(originalWinner.data.totalBudjet),
        date: updatedWinner.Date || originalWinner.data.date,
        img: imageUrl,
        listName: updatedWinner.listName || originalWinner.data.listName,
        groupMembers: memberArray,
        listItems: listArray,
      };

      console.log(updatedData)

      const response = await baseAPI.put(`winners/update/${id}`, updatedData);
      if (response) {
        setSuccess(true);
        setTimeout(() => {
          setModal(false);
        }, 1000);
      }
    } catch (error) {
      console.error("Error updating project:", error);
      alert("Failed to update project");
    }
  };

  const handleModal = () => {
    setModal(true);
    setSuccess(false);
  };

  if (originalWinner.isLoading) {
    return <div>Loading...</div>;
  }
  if (originalWinner.error) {
    return <div>Error Loading project</div>;
  }

  return (
    <main className="bg-neutral-100 ">
      <div className="w-full flex gap-3 pt-3 border-b pb-2 rounded-sm">
        <div className="flex flex-col gap-3 w-1/2 p-2">
          <label className="font-bold ml-2" htmlFor="file">
            სურათის შეცვლა
          </label>
          <input
            className="w-full, p-2 border "
            type="file"
            id="file"
            name="img"
            onChange={handleFileChange}
          />
        </div>
        <div className="border-2 flex w-1/2 h-80">
          <img
            src={image ? image : originalWinner.data.img}
            alt="Uploaded Img"
            className="h-full w-full object-cover"
          />
        </div>
      </div>

      <div className="w-full flex gap-5 mt-5 border-b pb-2">
        <CommonEditInput
          type="text"
          name="projectName"
          value={updatedWinner.projectName || originalWinner.data.winnerName }
          id="projectName"
          label="სათაური"
          handleChange={handleUpdateChange}
        />
        <CommonEditTextArea
          name="projectDesc"
          value={updatedWinner.projectDesc || originalWinner.data.projectDesription  }
          id="projectDesc"
          label="აბზაცი 1"
          handleChange={handleUpdateChange}
        />
      </div>

      <div className="w-full flex gap-5 mt-5 border-b pb-2">
        <CommonEditInput
          type="text"
          name="city"
          value={updatedWinner.city || originalWinner.data.city}
          id="city"
          label={"ქალაქი"}
          handleChange={handleUpdateChange}
        />

        <CommonEditInput
          type="date"
          name="Date"
          value={updatedWinner.Date || originalWinner.data.Date}
          id="Date"
          label={"თარიღი"}
          handleChange={handleUpdateChange}
        />
      </div>

      <div className="w-full flex gap-5 mt-5 border-b pb-2">
        <CommonEditInput
          type="text"
          name="budjetFromStartupGeorgia"
          value={
            updatedWinner.budjetFromStartupGeorgia ||
            originalWinner.data.budjetFromStartupGeorgia
          }
          id="budjetFromStartupGeorgia"
          label={"სტარტაპ საართველოს ინვესტიცია"}
          handleChange={handleUpdateChange}
        />

        <CommonEditInput
          type="text"
          name="totalBudget"
          value={updatedWinner.totalBudget || originalWinner.data.totalBudjet }
          id="totalBudget"
          label={"ჯამური ბიუჯეტი"}
          handleChange={handleUpdateChange}
        />
      </div>

      <div className="flex gap-5 mt-3 w-full border-b pb-2">
        <CommonEditInput
          type="text"
          name="member"
          value={updatedWinner.member}
          id="member"
          label={"ჯგუფის წევრები"}
          handleChange={handleUpdateChange}
          icon={true}
          handleAdd={() => {
            setMemberArray([...memberArray, updatedWinner.member]);
            setUpdatedWinner({
              ...updatedWinner,
              member: "",
            });
          }}
        />

        <div className="flex w-full gap-5 border-l items-center pl-4">
          <h3 className="font-semibold text:lg">Members:</h3>
          <ul className="flex gap-5 flex-wrap   ">
            {memberArray?.map((member, index) => (
              <li
                className=" text:lg cursor-pointer hover:text-red-500"
                onClick={() => {
                  const updatedArray = memberArray.filter(
                    (item) => item !== member
                  );
                  setMemberArray(updatedArray);
                }}
                key={index}
              >
                {member},
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="w-full flex gap-5 mt-5 border-b pb-2">
        <div className="flex flex-col gap-5 mt-7 w-full">
          <CommonEditInput
            type={"text"}
            name={"listName"}
            value={updatedWinner.listName || originalWinner.data.listName|| " "}
            id={"listName"}
            label={"ჩამონათვლის სათაური"}
            handleChange={handleUpdateChange}
          />
          <CommonEditInput
            type={"text"}
            name={"listItem"}
            value={updatedWinner.listItem}
            id={"listItem"}
            label={"ჩამონათვლის ელემენტი"}
            handleChange={handleUpdateChange}
            icon={true}
            handleAdd={() => {
              setListArray([...listArray, updatedWinner.listItem]);
              setUpdatedWinner({
                ...updatedWinner,
                listItem: "",
              });
            }}
          />
        </div>

        <div className="w-full flex flex-col gap-3 ">
          <h3 className="font-semibold text:lg border-b pb-2">
            {!updatedWinner.listName
              ? originalWinner.data.listName
              : updatedWinner.listName}
          </h3>

          <ul className="flex gap-3 flex-col list-disc pl-5 flex-wrap pr-5 ">
            {listArray?.map((list, index) => (
              <li
                className=" text:lg cursor-pointer hover:text-red-500"
                onClick={() => {
                  const updatedArray = listArray.filter(
                    (item) => item !== list
                  );
                  setListArray(updatedArray);
                }}
                key={index}
              >
                {list}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="w-full flex justify-end p-3 pr-8 mt-3">
        <button
          disabled={loading}
          onClick={handleModal}
          className="bg-textColor text-white p-3 mb-6 ml-5 mt-3 rounded-xl font-bold hover:opacity-90"
        >
          {loading ? "აფდეითდება..." : "შეცვლა"}
        </button>
      </div>
      {modal && (
        <CommonModal
          success={success}
          handleSubmit={() => handleSubmit(id)}
          setModal={setModal}
          successText=" პროექტი წარმატებით დააფდეითდა !!"
          question=" დარწმუნებული ხართ რომ გინდათ ცვლილებების შენახვა?"
        />
      )}
    </main>
  );
};

export default SingleWinner;