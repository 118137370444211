import { Formik, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useState } from "react";
import { v4 } from "uuid";
import { storage } from "../../Utils/firebase";
import { baseAPI } from "../../Services/baseApi";
import CommonFormikInput from "../../Components/CommonFormikInput";

const validationSchema = yup.object().shape({
  Date: yup.date(),
  img: yup.string().required("Image is required"),
  projectName: yup.string().required("Project name is required"),
  city: yup.string().required("City is required"),
  projectDesc: yup.string().required("Project description is required"),
  budjetFromStartupGeorgia: yup
    .number()
    .min(0, "Budget from Startup Georgia must be a non-negative number")
    .required("Budget from Startup Georgia is required"),
  totalBudget: yup
    .number()
    .min(0, "Total budget must be a non-negative number")
    .required("Total budget is required"),
  listName: yup.string(),
  listItems: yup.array().of(yup.string()),
});

const values = {
  Date: "",
  img: "",
  projectName: "",
  membername: "",
  city: "",
  groupMembers: [],
  projectDesc: "",
  budjetFromStartupGeorgia: 0,
  totalBudget: 0,
  listName: "",
  listItems: [],
  listItem: "",
};

const AddWinner = () => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpload = (file) => {
    setLoading(true);
    const imageRef = ref(storage, `projects/${file.name + v4()}`);
    return uploadBytes(imageRef, file)
      .then(() => getDownloadURL(imageRef))
      .finally(() => setLoading(false));
  };

  return (
    <main>
      <Formik
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          const currentDate = values.Date
            ? values.Date
            : new Date().toISOString().split("T")[0];
          handleImageUpload(values.img)
            .then((downloadUrl) => {
                const formData={ winnerName: values.projectName,
                    city: values.city,
                    projectDesription: values.projectDesc,
                    budjetFromStartupGeorgia: parseFloat(values.budjetFromStartupGeorgia),
                    totalBudjet: parseFloat(values.totalBudget),
                    listName: values.listName,
                    listItems: values.listItems,
                    groupMembers: values.groupMembers,
                    date: currentDate,
                    img: downloadUrl}
                    console.log(formData)
              return baseAPI.post("winners/addwinner", formData);
            })
            .then((res) => {
              if (res) {
                resetForm();
                setImage(null);
                alert("პროექტი წარმატებით დაემატა");
              }
            })
            .catch((error) => {
              console.error("Error adding news:", error);
              alert("პროექტი ვერ დაემატა");
            });
        }}
      >
        {({ values, setFieldValue }) => {
       
          return (
            <div className="bg-neutral-100 ">
              <Form className="pt-5">
                <div className="w-full flex gap-3 mt-3 border-b pb-2 rounded-sm">
                  <div className="flex flex-col gap-3 w-1/2 p-2">
                    <label className="font-bold ml-2" htmlFor="file">
                      სურათის დამატება
                    </label>
                    <input
                      className="w-full, p-2 border "
                      type="file"
                      id="file"
                      name="img"
                      onChange={(event) => {
                        setFieldValue("img", event.currentTarget.files[0]);
                        handleFileChange(event);
                      }}
                    />
                    <ErrorMessage
                      name="img"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="border-2 flex w-1/2 h-60 m-2 mr-5">
                    {image && (
                      <img
                        src={image}
                        alt="Uploaded Img"
                        className="h-full w-full object-cover"
                      />
                    )}
                  </div>
                </div>

                <div className="w-full flex gap-5 border-b pb-2 mt-5">
                  <div className="flex flex-col gap-3 w-full p-2 relative">
                    <label
                      className="font-normal text-gray-500 bg-neutral-100 z-20 ml-3 absolute t pl-2 pr-2 left-2"
                      htmlFor="Date"
                    >
                      თარიღი
                    </label>
                    <input
                      type="Date"
                      id="Date"
                      name="Date"
                      onChange={(event) =>
                        setFieldValue("Date", event.target.value)
                      }
                      className="w-full, p-2 border bg-neutral-100  border-gray-500 focus:outline-none "
                      value={values.Date}
                    />
                    <p className="text-red-500 text-sm ml-3">
                      <ErrorMessage name="Date" />
                    </p>
                  </div>

                  <CommonFormikInput
                    htmlfor="projectName"
                    label="პროექტი სახელი"
                    name="projectName"
                  />
                </div>

                <div className="w-full flex gap-5 border-b pb-2 pt-5">
                  <CommonFormikInput htmlfor="city" label="ქალაქი" name="city" />

                  <CommonFormikInput
                    htmlfor="projectDesc"
                    label="აღწერა"
                    name="projectDesc"
                  />
                </div>

                <div className="w-full flex gap-5 border-b pb-2  pt-5">
                  <CommonFormikInput
                    htmlfor="membername"
                    label="წევრის სახელი"
                    name="membername"
                    icon={true}
                    handleAdd={() => {
                      const updatedGroupMembers = [
                        ...values.groupMembers,
                        values.membername,
                      ];
                      setFieldValue("groupMembers", updatedGroupMembers);
                      setFieldValue("membername", "");
                    }}
                  />

                  <div className="w-full p-2 pl-4 border-l flex gap-2 items-start   ">
                    <h2 className="text-lg text-black font-semibold">
                      წევრები:
                    </h2>
                    <ul className="flex gap-2 flex-wrap">
                      {values.groupMembers.map((member, index) => (
                        <li
                          onClick={() => {
                            const updatedGroupMembers =
                              values.groupMembers.filter(
                                (item) => item !== member
                              );
                            setFieldValue("groupMembers", updatedGroupMembers);
                          }}
                          className="text-lg hover:text-red-500 cursor-pointer"
                          key={index}
                        >
                          {member},
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="w-full flex gap-5 border-b pb-2 pt-5">
                  <CommonFormikInput
                    htmlfor="budjetFromStartupGeorgia"
                    label="სტარტაპიდან მოთხოვნილი ბიუჯეტი"
                    name="budjetFromStartupGeorgia"
                  />
                  <CommonFormikInput
                    htmlfor="totalBudget"
                    label="სრული ბიუჯეტი"
                    name="totalBudget"
                  />
                </div>
                <div className="w-full flex gap-5  pt-5 border-b ">
                  <div className="w-full flex flex-col gap-2">
                    <CommonFormikInput
                      htmlfor="listName"
                      label="ჩამონათვლის სათაური"
                      name="listName"
                    />
                    <CommonFormikInput
                      htmlfor="listItem"
                      label="ჩმონათვალი"
                      name="listItem"
                      icon={true}
                      handleAdd={() => {
                        const updatedListItems = [
                          ...values.listItems,
                          values.listItem,
                        ];
                        setFieldValue("listItems", updatedListItems);
                        setFieldValue("listItem", "");
                      }}
                    />
                  </div>

                  <div className="w-full p-2 border-l pl-3 ">
                    <h2 className="text-lg text-black font-semibold border-b pb-2">
                      {!values.listName ? "ჩამონათვლის სახელი " : values.listName}
                    </h2>
                    <ul className="list-disc flex flex-col gap-2 ml-5 mt-2">
                      {values.listItems.map((item, index) => (
                        <li
                          className="text-lg hover:text-red-500 cursor-pointer"
                          onClick={() => {
                            const updatedListItems = values.listItems.filter(
                              (i) => i !== item
                            );
                            setFieldValue("listItems", updatedListItems);
                          }}
                          key={index}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="w-full flex justify-end p-3 pr-10">
                  <button
                    type="submit"
                    disabled={loading}
                    className="bg-textColor text-white p-3 mb-6 mr-5 rounded-xl font-bold hover:opacity-90"
                  >
                    {loading ? "პროექტი ემატება..." : "დამატება"}
                  </button>
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </main>
  );
};

export default AddWinner;